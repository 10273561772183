import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import Link from 'components/Link';
import RegLink from 'components/RegLink';
import * as styles from 'pages/pages.module.css';
import * as s from './GetStarted.module.scss';
import WL from 'constants/whiteLabel';

class GetStarted extends React.Component {
  render () {
    return (
      <section className={[styles.content, s.getStarted].join(' ')}>
        <div className={styles.wrapper}>
          <div className={s.call}>
            <h3 className={cx(styles.accent, s.accent)}>
              <FormattedMessage id="ready_to_start" defaultMessage="Ready to Get started?" values={
                {
                  span: (...chunks) => <span className={cx('highlighted-text')}>{chunks}</span>,
                }
              }/>
            </h3>
            <p>
              <FormattedMessage id="free_home_mining" defaultMessage="Free for home mining! " />
              <Link 
                data-gtm-category="site_footer"
                data-gtm-action="pricing_click"
                data-gtm-label={JSON.stringify({ button: 'pay_as_you_grow' })}
                withLang 
                to="/pricing" className={styles.link}
              >
                  <FormattedMessage id="pay_as_grow" defaultMessage="Pay as you grow" />
                </Link>
            </p>
          </div>
          <div className={s.btnHolder}>
            <RegLink 
              data-gtm-category="site_footer"
              data-gtm-action="get_started_click"
              data-gtm-label={JSON.stringify({ button: 'get_started' })}
              external 
              noRef
              className={cx("btn lg-x")} 
              shadowElement={true} 
              target={WL.regLinkTarget}
            > 
              <FormattedMessage id="get_started" defaultMessage="Get Started" />
            </RegLink>
          </div>
        </div>
      </section>
    )
  }
}

export default GetStarted;
