// extracted by mini-css-extract-plugin
export var root = "SingleComment-module--root--ldgvL";
export var testimonial = "SingleComment-module--testimonial--hsDOT";
export var small = "SingleComment-module--small--AS9Pe";
export var text = "SingleComment-module--text--LWVlo";
export var expanded = "SingleComment-module--expanded--rb-2W";
export var nick = "SingleComment-module--nick--Ka51X";
export var textExpanded = "SingleComment-module--textExpanded--85570";
export var textBlur = "SingleComment-module--textBlur--HFs+O";
export var expandableBlur = "SingleComment-module--expandableBlur--99Eqo";
export var ava = "SingleComment-module--ava--ASZOU";
export var userWrapper = "SingleComment-module--userWrapper--HGEsp";
export var user = "SingleComment-module--user--572qf";
export var empty = "SingleComment-module--empty--vDO6G";