export const MAX_MEDIA_SM = '(max-width: 640px)';
export const MAX_MEDIA_MD = '(max-width: 768px)';
export const MAX_MEDIA_LG = '(max-width: 1024px)';
export const MAX_MEDIA_XL = '(max-width: 1280px)';
export const MAX_MEDIA_2XL = '(max-width: 1536px)';

export const MIN_MEDIA_SM = '(min-width: 640px)';
export const MIN_MEDIA_MD = '(min-width: 768px)';
export const MIN_MEDIA_LG = '(min-width: 1024px)';
export const MIN_MEDIA_XL = '(min-width: 1280px)';
export const MIN_MEDIA_2XL = '(min-width: 1536px)';
