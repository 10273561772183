import { useState, useEffect } from "react";

const useMediaQuery = (query, resize = false) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    if (resize) {
      window.addEventListener("resize", listener);
    }
    return () => {
      if (resize) {
        window.removeEventListener("resize", listener);
      }
    };
  }, [matches, query]);

  return matches;
}

export default useMediaQuery;
