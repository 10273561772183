import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const Image = function ({styles = {}, ...props}) {
  return (
    <StaticQuery
      query={graphql`{
      images: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            extension
            relativePath
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
`}

      render={(data) => {
        const originImage = data.images.edges.find(
          image => {
            return image.node.relativePath === props.src
          }
        );

        const size = {}

        if (props.width) {
          size.width = props.width;
        }

        if (props.height) {
          size.height = props.height;
        }

        if (!originImage || !originImage.node || !originImage.node.childImageSharp) {
          console.error("Image not found", props.src);
          return null;
        }

        if (!size) {
          return <GatsbyImage image={originImage.node.childImageSharp.gatsbyImageData} {...props} />;
        }

        return (
          <div style={{ width: size.width, height: size.height, position: 'relative', ...styles }}>
            <GatsbyImage image={originImage.node.childImageSharp.gatsbyImageData} {...props} />
          </div>
        );
      }}
    />
  );
}

export default Image;
