import React, { useState , useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SingleComment from 'components/Testimonials/SingleComment';
import _get from 'lodash/get';
import _chunk from 'lodash/chunk';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper";
// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";

import * as gs from 'pages/pages.module.css';
import * as s from 'components/Testimonials/Testimonials.module.css';

import useMediaQuery from 'hooks/useMediaQuery';
import { MAX_MEDIA_SM } from 'constants/customMedia';

const list = {
  en: [
    {
      name: '/../',
      avatar: 'avas/lara.png',
      nick: '',
      href: null,
      comment: 'I want to thank you for the awesome product. Please keep at it. I\'ve been supporting hive from the start and tried every platform out there. Hive is by far the best',
    },
    {
      name: 'Chris Hammerich',
      avatar: 'avas/chris.png',
      nick: '',
      href: null,
      comment: 'This software is absolutely phenomenal when you have dedicated miners. You can control absolutely everything. Fan speeds has been huge for me while I&apos;m at work to adjust. Every option is available with a click to adjust. Fan Speeds, Temp, Core/Memory',
    },
    {
      name: 'Jatmikoclasher',
      avatar: 'avas/j.png',
      nick: '',
      href: null,
      comment: 'Best Mining Pool. Im currently using HiveOn pool, this pool have more profit than another pool and have best UI so far. With fast online support team, you can get information and explanation so fast.',
    },
    {
      name: '. .',
      avatar: 'avas/dot.png',
      nick: '',
      href: null,
      comment: "I'm finding the new platform very good, it's more intuitive and I'm sure it will get even better. thank you so much.",
    },
    {
      name: 'Pontus Lindberg',
      avatar: 'avas/pontus.png',
      nick: '@PontusLSE',
      href: null,
      comment: 'Wow, who figured that Hive could become even better than it already was. Hive2 is amazing!',
    },
    {
      name: 'Steffen Nesper',
      avatar: 'avas/steffen.png',
      nick: '',
      href: null,
      comment: 'Mining got so much easier with HiveOS. It is basically plug and play. No fizzling with drivers, the OS itself like Win10 etc. Also managing complex scenarios/farms never was easier with it. Also their support is worldclass.',
    },
    {
      name: 'Christopher Hartman',
      avatar: 'avas/christopher.png',
      nick: "@chrishrtmn",
      href: "https://twitter.com/@chrishrtmn",
      comment: 'As a miner coming from Windows, HiveOS has been a godsend. Simple install, beautiful user interface, rig notifications, and easy to use all for a low cost. Keep up the amazing work!',
    },
    {
      name: 'Johnathon Winston',
      avatar: 'avas/johnathon.png',
      nick: "@johnathon.winston",
      href: "https://www.facebook.com/johnathon.winston",
      comment: 'I currently have test piloted 4 rigs with Hive OS and have found it far exceeds my expectations for a complete mining solution. Developers are actively engaged in the community and have fast turn around time for improvements. This is absolutely essential for such competitive business. I plan on adding my remaining rigs to thrive my growth and partner with Hive for the future.',
    },
    {
      name: 'Ricardo Mulder',
      avatar: 'avas/ricardo.png',
      nick: "@ricardo.mulder",
      href: "https://www.facebook.com/ricardo.mulder",
      comment: 'We are mining for some time now with a group. We have total of 6 rigs running with currently 70+ GPUs. We have used windows and Simple Mining OS for some time and SMos even for a longer time (est. 6 months). Since end of January we now use HiveOS on almost all our rigs. It runs very stable, has lots of unique features like Telegram Notifications, good and powerful OC options, lots of good graphics too see the rig health and farm health. Overall for us it’s a big improvement so we moved most of our farm already to HiveOS and the rest will be moved soon. With this very active dev team we also see much new features getting released constantly.',
    },
    {
      name: 'Bruno Miguel',
      avatar: 'avas/brunoMiguel.png',
      nick: "@_brunnomiguel_",
      href: null,
      comment: 'Free all small user here oh @hiveonofficial for I er 3 years. Outstanding, no issues whatsoever and Customer Service support on Telegram...awesome...and I\'m using free version 👌',
    },
    {
      name: 'Turquoise Scissors',
      avatar: 'avas/tu.png',
      nick: '',
      href: null,
      comment: 'Thank you for this incredible software. Bye bye Windows 😉 I start to use from yesterday and it\'s pretty easy to use .. I immediately forget windows 😂 I can\'t mine ETH with my 4gb GPU on windows because the dag is too big 3,9 gb but with Hive OS it\'s possible ! wonderful',
    },
    {
      name: 'Ricardo Machado',
      avatar: 'avas/RM.png',
      nick: '@caipirinha',
      href: null,
      comment: 'I want to record here my satisfaction with HiveOS Technical Support. After mining failure at HiveOn Pool with NAVI boards (RX 5700 XT). He had the patience and technical experience to guide me and solve the problem. Happy to mine again with HiveOs.',
    },
    {
      name: 'Edgaras',
      avatar: 'avas/ed.png',
      nick: '@d0231128g',
      href: null,
      comment: 'Great support , easy and fast answer my questions . 10 of 10 stars',
    },
    {
      name: 'Miron',
      avatar: 'avas/mi.png',
      nick: '@Theshokh',
      href: null,
      comment: 'I use HiveOS for 6 month and i am very happy that i started my mining journey with Hive OS team. Right now i have 3 Rigs all of them works on Hive. Also supporting is very best, 10/10 thank you.👍👍',
    },
  ],
  ru: [
    {
      name: 'Root Binbash',
      avatar: 'avas/binbash.png',
      nick: '@root_binbash',
      href: null,
      comment: 'чуть не кончил от нового интерфейса! @DimaFern крутая работа!!!всей команде супер!!! вы лучшие! единственная платформа такая на рынке',
    },
    {
      name: 'Алексей Перевощиков',
      avatar: 'avas/alex.png',
      nick: "@alexeyminer",
      href: null,
      comment: 'До чего же крутым, удобным и красивым хайв получается! Прям вот чем больше ковыряюсь в нем, тем больше он мне нравится! Это точно движение в правильном направении! Систему равной хайву после такого уже никому создать будет неподсилу. Дмитрий Щербина это как Билл Гейтс теперь будет, но в среде майнеров!)',
    },
    {
      name: 'Константин',
      avatar: 'avas/push.png',
      nick: '@vakhanal',
      href: null,
      comment: 'Oгромная благодарность за проект, сэкономленное время, нервы, за вашу всегда оперативную помосч. Вам спасибо :) продолжайте в том же духе',
    },
    {
      name: 'Faridun K',
      avatar: 'avas/avaFk.png',
      nick: "@faridun_tjk",
      href: null,
      comment: 'Нет слов ребята молодцы я честно удивлен. Жалко, что я год тому назад не знал про вас😉😉лучше поздно, чем никогда 🙈🙈 На вашем сервисе человек может надеется на лучшее, зная, что если проблемы будут, то тебя так без помощи не оставлят) я хочу что бы те, кто не знали вас как я, перешли на вас🙏🙏🙏. Вниш уже как5 месяц обещает но нет релизов) а у вас просто 😍😍 я буду всем говорить своим друзьям четсно Сервис бомба🔥🔥🔥',
    },
    {
      name: 'Oleg Sinyakov',
      avatar: 'avas/oleg-s.png',
      nick: '',
      href: null,
      comment: 'Hive просто супер. С ним мониторинг стал в разы проще. Любой стопор в работе - сразу виден. Сам контролирует перегрев. Этим жарким летом - он просто стал спасением. Куча майнеров на борту и минимум настроек. Я его обожаю. Желаю команде развития и чтоб Hive работал всё лучше и лучше :)',
    },
    {
      name: 'Nibbler Vov4ik',
      avatar: 'avas/ZnNibbler.png',
      nick: "@ZnNibbler",
      href: null,
      comment: 'Уже давно юзаю клиент хайва на асиках L3+. Проблем никаких нету. Установка через SSH, вся инструкция есть на гитхаб. Также можно найти видео в ютубе. Очень удобно в использовании, не нужно сканировать ip в сети что бы искать устройства. Полётные листы с разными пулами. Вся статистика по ошибкам и хешрейтам на одной страничке.',
    },
    {
      name: '3aK',
      avatar: 'avas/Gebto.png',
      nick: '@Gebto',
      href: null,
      comment: 'Что-то хорошее настроение с утра и решил дать подтверждение разрабам Хайва Ребята, вы реально такой крутой продукт запилили! Раньше со своими 50 картами на 7 фермах сидел на Винде на найсе. Периодически, пусть и не часто что-то глючило, какие-то программные ошибки возникали и в добавок было не комфортное чувство неэтичности за использование пиратских Винд. Сейчас же закинул денег на три месяца вперёд за Хайв, плачу за работающую майнинговую среду и всем доволен. Этот обмен более, чем равноценный. Работает прекрасно и стабильно, не глючит, мониторить удобно, супер! Спасибо за ваши труды и процветания всей команде Хайва! 👍',
    },
    {
      name: 'Егор',
      avatar: 'avas/Egor.png',
      nick: '',
      href: null,
      comment: 'Как владелец майнинг-отела, хочу сказать, что это самое технологическое предложение на рынке и после того, как мои технические специалисты показали все прелести Вашей системы, я радовался как ребёнок! Боюсь представить, сколько проблем я закрою благодаря вам, а уж про то, что клиенты будут хлопать в ладоши - я вообще молчу... :) В свою очередь, обязательно на всей площадке и в офисе продаж расклею Ваш логотип и пропишу, что мы используем систему Hive OS для мониторинга.',
    },
    {
      name: 'Andrew',
      avatar: 'avas/andrew.png',
      nick: '',
      href: null,
      comment: 'Cпасибо команде хайва за отличный продукт. Давно занимаюсь майнингом, попробовал много систем и могу сказать, что это лучшее решение на рынке на данный момент.',
    },
    {
      name: 'Ivan',
      avatar: 'avas/ivan.png',
      nick: '',
      href: null,
      comment: 'Уже 100 лет как с приходом HiveOS забыл про такие анахронизмы как батники, скачивание майнеров, утилиты для прошивки, поиски драйверов. Все нужное сразу же в комплекте. + Удаленный мониторинг без шаманства и стороннего ПО, а сразу же предоставляется. Уж не говоря про то, что на линуксовых ОС под майнинг у тебя не будет ничего лишнего несвязанного с майнингом.',
    },
    {
      name: 'Topsy Kretts',
      avatar: 'avas/tkretts_pro.png',
      nick: "@tkretts_pro",
      href: null,
      comment: 'Удобно в том плане, что залил риг конфиг, прописал вайфай настройки, и вставил в любой риг, и без моника и участия всё завелось. Восхищаюсь хайвом)',
    },
    {
      name: 'Enot_HD',
      avatar: 'avas/EnotHD.png',
      nick: "@EnotHD",
      href: null,
      comment: 'Больше года с вами. ни разу не пожалел. вы крутые) Спасибо еще раз и удачи. отличная работа)',
    },
    {
      name: 'Артем',
      avatar: 'avas/ar.png',
      nick: '@ensokg',
      href: null,
      comment: 'Просто за 2 года я первый раз пишу в саппорт хайва. Не было нужды) Спасибо Вы лучшие ) Только жадные не пользуются хайвом. Лучший сервис для ригов.',
    },
    {
      name: 'Кирилл',
      avatar: 'avas/ki.png',
      nick: '',
      href: null,
      comment: 'Блин, как хорошо, что я на хайве. Ваще ребята-  вы крутые.',
    },
    {
      name: 'Виталий',
      avatar: 'avas/vi.png',
      nick: '',
      href: null,
      comment: 'Всё-таки хайв крутая штука, я на него перешел с винды, от которой меня просто бомбит. Еще не знаю всех проблем здесь, но хайв мне капец как нравится. Респект разработчикам за хороший продукт 😎а поддержка ваще огонь!',
    },
    {
      name: 'Андрей',
      avatar: 'avas/an.png',
      nick: '',
      href: null,
      comment: 'Ну что могу сказать. За 2 часа в хайв, он гораздо продуктивнее как ОС для майнинга. В найс такие настройки не сделать без беганья с картой от рига к компу.',
    },
    {
      name: 'Дмитрий',
      avatar: 'avas/dm.png',
      nick: '',
      href: null,
      comment: 'Хочу выразить благодарность команде хайва и комьюнити за помощь в настройке карт от нави!',
    },
    {
      name: 'Сергей',
      avatar: 'avas/ce.png',
      nick: '',
      href: null,
      comment: 'Какая красотища, прописал в конфе старый айди и пароль, поменял флешку и все завелось, как и было настроено. Спасибо вам разработчики, я задумался надо покупкой :-)',
    },
    {
      name: 'Faridun',
      avatar: 'avas/fa.png',
      nick: '',
      href: null,
      comment: 'Хочу выразить свою благодарность этой системе, этой группе и команде, за то что помогали мне в течени 2 дней и настраивали мои фермы. Просто супер, Молодцы ребята. Спасибо большое. Я такую оддержке нигде не видел. И система просто огонь - теперь где бы я бы не был, я могу следить за своим асиками, не переживаю о том, что они перегреваются или отключенные🙏🙏',
    },
  ],
};

const swiperSettings = {
  className: 'swiperTestimonials',
  spaceBetween: 36,
  slidesPerView: 1,
  autoplay: {
    delay: 3000,
  },
  loop: true,
  loopedSlides: Infinity,
  pagination: {
    clickable: true,
    enabled: true,
  },
  modules: [Autoplay, Pagination],

  breakpoints: {
    640: {
      slidesPerView: 'auto',
      pagination: {
        enabled: false,
      },
      allowTouchMove: false,
      autoplay: {
        delay: 0,
        pauseOnMouseEnter: false,
        waitForTransition: false,
        // disableOnInteraction: false,
      },
      speed: 9000,
    }
  }
}


const Testimonials = (props) => {

  const [comments, setComments] = useState([]);
  const [chunkComments, setChunkComments] = useState([]);
  const isMobile = useMediaQuery(MAX_MEDIA_SM);

  useEffect(() => {
    getComments()
  }, []);

  const getComments = () => {
    const language = _get(props, 'language', 'en');
    const randomised = _get(list, language, []).sort(() =>  Math.random() - 0.5);    
    let comments = randomised;

    const order = (arr) => {
      let semaphore = true;
      const pyramid = [];

      arr.forEach(item => {
        if (semaphore) {
          pyramid.push(item);
        } else {
          pyramid.unshift(item);
        }

        semaphore = !semaphore;
      })

      return pyramid;
    }

    comments = order(comments);
    const chunkComments = _chunk(comments, 2)
    
    setComments(comments)
    setChunkComments(chunkComments)
  }

  return (
    <section className={cx(gs.content, s.section)}>
      <div className={gs.wrapper}>
        <h2 className={s.title}>
          <FormattedMessage id="testimonials" defaultMessage="Testimonials" />
        </h2>
      </div>
      <div className={s.root}>
        <Swiper {...swiperSettings}>
          {isMobile ?  comments.map((comment, index) => (
            <SwiperSlide key={`swipe-mobile-${index}`} className={s.swiperSlide}>
              <SingleComment key={`swipe-mobile-${index}`} {...comment} />
            </SwiperSlide> 
          )) : 
          chunkComments.map((comment, commentIndex) => {
            const isSmallComment = commentIndex % 2 === 0;
            return (
              <SwiperSlide key={`swip-${commentIndex}`} className={s.swiperSlide}>
                {comment.map((item, index) => {
                  const isSmallItem = index === 0 ? isSmallComment : !isSmallComment ;  

                  return <SingleComment key={`single-comment-${index}`} {...item} small={isSmallItem} />
                })}
              
              </SwiperSlide> 
            )})
        }
        </Swiper>
      </div>
    </section>
  )
}


const TestimonialsComp = () => {
  const intl = useIntl();
  return (
    <Testimonials language={intl.locale} />
  )
}

export default TestimonialsComp;
