import React, { Component } from 'react';
import cx from 'classnames';
import Img from 'components/Image';
import Link from 'components/Link';
import * as s from './SingleComment.module.css';

class SingleComment extends Component {
  constructor(props) {
    super(props);
  }

  render () {
    const { comment, small, avatar, href, name, nick } = this.props;

    return (
      <div className={s.root}>
        <div>
          <div className={cx(s.testimonial, { [s.small]: small })}>
          <div
            className={cx(s.text)}
            role="presentation"
          >
            {comment}
          </div>
        </div>
        </div>
        <div className={s.userWrapper}>
          <div className={s.ava}><Img src={avatar} alt="avatar image"/></div>
          <div className={cx(s.user, {[s.empty]: !nick})}>
              { name }
          </div>
          { nick && !href && <div className={s.nick}>{nick}</div> }
          { href && <Link external href={href} className={s.nick}>{nick}</Link> }
        </div>
      </div>
    );
  }
}

export default SingleComment;
